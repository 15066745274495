import {SmartComponent} from "smart-component-js";

class PlayCodeComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;

        this.init();
    }


    takeCode(){
        document.addEventListener("yxp-loyalty-coupon-ok", (e) => {
            this.code = e.detail.body.code;
            window.location.href = `/en/reserved/instant-win?code=${this.code}`

            this.cta = this.element.querySelector('.cta.go-away');
            this.cta.remove();
        });
    }


    init() {

        this.takeCode();
    }
}

export default PlayCodeComponent;