import "../../styles/sofidelcouponit/sofidelcouponit.scss";
import {SmartComponentManager} from "smart-component-js";
import DefaultCtrl from "./controllers/DefaultCtrl";
import HeaderComponent from "../common/components/HeaderComponent";
import FooterComponent from "../common/components/FooterComponent";
import OnBoardingComponent from "../common/components/OnBoardingComponent";
import InstantWinComponent from "../common/components/InstantWinComponent";
import PlayCodeComponent from "../common/components/PlayCodeComponent";
import RewardFormComponent from "../common/components/RewardFormComponent";
import ProductCarouselComponent from "../common/components/ProductCarouselComponent";
import HomeComponent from "../common/components/HomeComponent";
import UploadReceiptComponent from "../common/components/UploadReceiptComponent";
import MyActivitiesComponent from "../common/components/MyActivitiesComponent";
import YouWinComponent from "../common/components/YouWinComponent";
import $ from 'jquery';

SmartComponentManager.registerComponents({
    DefaultCtrl,
    HeaderComponent,
    FooterComponent,
    OnBoardingComponent,
    InstantWinComponent,
    PlayCodeComponent,
    RewardFormComponent,
    ProductCarouselComponent,
    HomeComponent,
   UploadReceiptComponent,
    MyActivitiesComponent,
    YouWinComponent
});

$(document).ready(() => {
    let $body = $('body [component="DefaultCtrl"]');
    SmartComponentManager.initComponentByName($body[0], $body.attr('component'));
});

