import {SmartComponent} from "smart-component-js";

class HomeComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }


    init() {
        const userId = this.element.dataset.userId;
        if (userId) this.element.querySelector('.not-logged-user-box').classList.add('hidden');
    }
}

export default HomeComponent;