import {SmartComponent} from "smart-component-js";

class OnBoardingComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.endpoint = `/api/1.0/raas/users/${window.SITE_NAME}`;
        this.init();
    }

    checkConsents(data) {
        this.element.classList.remove('loading');
        const privacyMarketing = data.consents.find(el => el.consentName === 'PRIVACY_MARKETING');
        const privacyProfile = data.consents.find(el => el.consentName === 'PRIVACY_PROFILE');
        if (privacyMarketing && privacyMarketing.consent) {
            this.element.querySelector('div[name="PRIVACY_MARKETING"]').closest('.yxpRow').classList.add('hidden');
        }

        if (privacyProfile && privacyProfile.consent) {
            this.element.querySelector('div[name="PRIVACY_PROFILE"]').closest('.yxpRow').classList.add('hidden');
        }

        if (privacyMarketing?.consent && privacyProfile?.consent) {
            this.element.querySelector('form .yxpType_TEXTAREA').classList.add('hidden');
        }
    }


    getUserInfo() {
        youserxp.ajax(this.endpoint, {method: 'GET', auth: true}).then(
            (data) => this.checkConsents(data.body),
            (err) => console.log(err)
        );
    }

    init() {
        this.element.classList.add('loading');
        document.addEventListener("yxp-form-loaded", () => this.getUserInfo());

    }
}

export default OnBoardingComponent;
