import {SmartComponent} from "smart-component-js";

class UploadReceiptComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }


    takeIdReceipt(){
        document.addEventListener("yxp-loyalty-receipt-ok", (e) => {
            this.message = this.element.querySelector('.yxp-rw2-success-wrapper');
            this.message.remove();
             this.id = e.detail.body.id;
             window.location.href = `/hu/reserved/instant-win?idReceipt=${this.id}`
            this.cta = this.element.querySelector('.cta.go-away');
            this.cta.remove();
        });
    }


    init() {
        this.takeIdReceipt();
    }
}

export default UploadReceiptComponent;